import React, { useState } from "react";
import { Loader2Icon, DownloadIcon, ExternalLinkIcon } from "lucide-react";

const DownloadReport = ({ tabName }) => {
  const [downloading, setDownloading] = useState(false);

  // const serverUrl = "http://65.0.182.200:8000/download-report";
  // const devUrl = "http://127.0.0.1:8000/download-report";
  const serverUrl = "https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/download"
  // const devUrl = "http://localhost:8000/api/alpha-vs-nifty-charts/download";

  const handleViewHtml = (htmlContent) => {
    if (htmlContent) {
      // Create a Blob containing the HTML content
      const blob = new Blob([htmlContent], { type: "text/html" });

      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);

      // Open the URL in a new tab
      window.open(url, "_blank");
    } else {
      console.warn("No HTML content to view!");
    }
  };

  const fetchHtmlReport = async () => {
    setDownloading(true);

    const url = `${serverUrl}/${tabName}`;

    try {
      const response = await fetch(url);

      const html = await response.text();

      if (html) {
        handleViewHtml(html);
        handleDownloadHtml(html);
        setDownloading(false);
      } else {
        setDownloading(false);
      }
    } catch (error) {
      setDownloading(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleDownloadHtml = (htmlContent) => {
    if (htmlContent) {
      // Trigger file download
      const blob = new Blob([htmlContent], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${tabName}-full-report.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      console.warn("No HTML content to download!");
      //   handleViewHtml(htmlContent);
    }
  };

  return (
    <div className="flex ">
      <button
        onClick={fetchHtmlReport}
        className="flex items-center justify-center space-x-2 w-full  bg-[#14141C] hover:bg-[#14141C]/80 text-white font-semibold py-2 px-4 rounded"
      >
        {downloading ? (
          <div className="flex space-x-1 items-center justify-center">
            <Loader2Icon className="h-4 w-4 animate-spin" />
            <span>Downloading ...</span>
          </div>
        ) : (
          <>
            <DownloadIcon className="h-4 w-4" />
            <span> View Report</span>
          </>
        )}
      </button>
    </div>
  );
};

export default DownloadReport;
