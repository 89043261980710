import React from "react";
//React Icons
import { AiOutlineLogout } from "react-icons/ai";
import { auth } from "../../firebase";
import Logo from "../../assets/SecondStreet_logo(w).svg";
import { Link } from "react-router-dom";
const style = {
  selectedNav:
    "ml-4 px-3 pt-8 pb-4 text-white border-b-2 border-[#18b13f] cursor-pointer font-montserrat",
  unSelectedNav:
    "ml-4 px-3 pt-8 pb-4 text-[#ffffff]/60 border-b-2 border-[#000000] cursor-pointer font-montserrat",
  selectedNavIndia:
    "ml-4 px-3 pt-8 pb-4 text-white border-b-2 border-[#18b13f] cursor-pointer font-montserrat",
  unSelectedNavIndia:
    "ml-4 px-3 pt-8 pb-4 text-[#ffffff]/60 border-b-2 border-[#14141C] cursor-pointer font-montserrat",
  unSelectedNavIndiaOne:
    "ml-4 px-3 pt-8 pb-4 text-[#ffffff]/60 border-b-2 border-[#000000] cursor-pointer font-montserrat",
};
const Navbar = (props) => {
  return (
    <>
      <nav
        className={
          window.location.pathname === "/comparison"
            ? `w-full flex flex-row items-center fixed top-0 shadow-sm bg-[#14141C] z-50 font-Poppins`
            : `w-full flex flex-row items-center fixed top-0 shadow-sm bg-[#000000] z-50 font-Poppins border-b border-[#14141C]`
        }
      >
        {/*------------------Logo--------------------------*/}

        <div className="flex flex-row items-center justify-center py-2 ml-10">
          <img src={Logo} alt="Logo" width={150} />
        </div>

        {/*------------------NAV LINK--------------------------*/}

        <div className="w-3/4 flex flex-row mr-3 justify-center items-center">
          <Link to="/">
            <div
              className={
                window.location.pathname === "/"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha 50
            </div>
          </Link>
          <Link to="/alpha-100">
            <div
              className={
                window.location.pathname === "/alpha-100"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha 100
            </div>
          </Link>
          <Link to="/alpha-200">
            <div
              className={
                window.location.pathname === "/alpha-200"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha 200
            </div>
          </Link>
          <Link to="/alpha-500">
            {" "}
            <div
              className={
                window.location.pathname === "/alpha-500"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha 500
            </div>
          </Link>
          <Link to="/alpha-s&p">
            <div
              className={
                window.location.pathname === "/alpha-s&p"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha S&P
            </div>
          </Link>
          <Link to="/alpha-nasdaq">
            <div
              className={
                window.location.pathname === "/alpha-nasdaq"
                  ? style.selectedNav
                  : window.location.pathname === "/comparison"
                  ? style.unSelectedNavIndia
                  : style.unSelectedNav
              }
            >
              Alpha NDX
            </div>
          </Link>
          <Link to="/comparison">
            <div
              className={
                window.location.pathname === "/comparison"
                  ? style.selectedNavIndia
                  : style.unSelectedNavIndiaOne
              }
            >
              Comparison
            </div>
          </Link>
        </div>
        <div
          to="#!"
          className="flex text-white text-center items-center border-[1px] border-[#ffffff]/40 hover:border-[#ffffff] px-4 py-1 rounded-lg cursor-pointer"
          onClick={() => {
            auth.signOut();
          }}
        >
          {" "}
          <AiOutlineLogout className="mr-2" />
          Logout
        </div>
      </nav>
    </>
  );
};

export default Navbar;
